<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">证书管理</a>
          <i>></i>
          <a href="javascript:;">证书列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div class="searchboxItem">
              <span class="itemLabel">证书名称:</span>
              <el-input
                v-model="certName"
                type="text"
                size="small"
                style="width: auto"
                placeholder="请输入证书名称"
                clearable
              />
            </div>
            <div class="searchboxItem">
              <span class="itemLabel">证书标签:</span>
              <el-input
                v-model="certLabel"
                type="text"
                size="small"
                style="width: auto"
                placeholder="请输入证书标签"
                clearable
              />
            </div>
            <div class="searchboxItem">
              <span class="itemLabel">上架状态:</span>
              <el-select
                clearable
                v-model="certState"
                placeholder="请选择上架状态"
                size="small"
              >
                <el-option
                  v-for="item in certStateList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
            <div class="searchboxItem">
              <span class="itemLabel">招生状态:</span>
              <el-select
                clearable
                v-model="certRecruitState"
                placeholder="请选择招生状态"
                size="small"
              >
                <el-option
                  v-for="item in certRecruitStateList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>

            <div>
              <el-button class="bgc-bv" type="primary" @click="getData()"
                >查询</el-button
              >
              <el-button
                class="bgc-bv"
                type="primary"
                @click="EditDialog('add')"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :header-cell-style="tableHeader"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column label="序号" align="center" type="index" />
              <el-table-column
                label="证书名称"
                align="center"
                prop="certName"
                :show-overflow-tooltip="true"
              />
              <el-table-column
                label="分类"
                align="center"
                prop="certTypeName"
                :show-overflow-tooltip="true"
              />
              <el-table-column label="封面" align="center" prop="certCoverUrl">
                <template slot-scope="scope">
                  <el-image
                    style="width: 30px; height: 30px; border-radius: 50%"
                    :src="scope.row.certCoverUrl"
                    :preview-src-list="[scope.row.certCoverUrl]"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-folder-delete"></i>
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column
                label="标签"
                align="center"
                prop="certLabel"
                :show-overflow-tooltip="true"
              />
              <el-table-column
                label="开始时间"
                align="center"
                width="140"
                prop="startTime"
              >
                <template slot-scope="scope">
                  {{ scope.row.startTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="结束时间"
                align="center"
                width="140"
                prop="endTime"
              >
                <template slot-scope="scope">
                  {{ scope.row.endTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="创建日期"
                align="center"
                width="140"
                prop="createTime"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="已报名人数"
                align="center"
                prop="applyNum"
              />
              <el-table-column
                label="累计分享人次"
                width="120"
                align="center"
                prop="shareNum"
              />

              <el-table-column label="上架状态" align="center" prop="certState">
                <template slot-scope="scope">
                  {{ $setDictionary("MINICERTSTATE", scope.row.certState) }}
                </template>
              </el-table-column>
              <el-table-column
                label="招生状态"
                align="center"
                prop="certRecruitState"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "MINI_CERT_RECRUIT_STATE",
                      scope.row.certRecruitState
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="280px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click="EditDialog('edit', scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    @click="certificateRelease(scope.row.certId)"
                    :disabled="
                      scope.row.certState == '10' &&
                      scope.row.certRecruitState == '40'
                    "
                    >{{
                      scope.row.certState == "10" ? "上架" : "下架"
                    }}</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    :disabled="scope.row.certState == '10'"
                    @click="certificateStopEnrollment(scope.row.certId)"
                    >终止招生</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    @click="certificateViewEnrollment(scope.row.certId)"
                    >查看报名</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @getData="getData"
          @sizeChange="sizeChange"
        />
      </div>
    </div>
    <el-dialog
      v-if="DialogVisible"
      :title="DialogTitle"
      :visible.sync="DialogVisible"
      width="50%"
      center
      @close="DialogCancel"
    >
      <el-form
        ref="DialogFromRef"
        :model="DialogFrom"
        :rules="DialogFromRules"
        label-width="120px"
        size="small"
      >
        <el-form-item label="证书名称：" prop="certName" size>
          <el-input v-model="DialogFrom.certName"></el-input>
        </el-form-item>
        <el-form-item label="证书分类：" prop="certType">
          <el-select
            v-model="DialogFrom.certType"
            placeholder="请选择"
            @change="change"
          >
            <el-option
              v-for="item in courseTypeTree"
              :key="item.certCategoryId"
              :label="item.certCategoryName"
              :value="item.certCategoryId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="证书等级：" prop="certLevel">
          <el-select v-model="DialogFrom.certLevel" placeholder="请选择">
            <el-option
              v-for="item in certLevelData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市场价格：" prop="certPrice">
          <el-input-number
            v-model="DialogFrom.certPrice"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="招生时间：" prop="recruitStudentsTime">
          <el-date-picker
            v-model="DialogFrom.recruitStudentsTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="展示封面：" prop="certCoverUrl">
          <el-upload
            :on-change="handlebusinessLicense"
            :before-upload="$beforeAvatarUpload"
            :http-request="$requestMine"
            :show-file-list="false"
            class="img-el-upload"
            action
            accept="image/png, image/gif, image/jpg, image/jpeg"
          >
            <el-image
              :src="DialogFrom.certCoverUrl || require('@/assets/develop.png')"
              fit="contain"
              class="imgCenter"
            ></el-image>
          </el-upload>
        </el-form-item>
        <el-form-item label="内容展示：" prop="certPhotoUrl">
          <el-upload
            :on-change="handlebusinessLicense1"
            :before-upload="$beforeAvatarUpload"
            :http-request="$requestMine"
            :show-file-list="false"
            class="img-el-upload"
            action
            accept="image/png, image/gif, image/jpg, image/jpeg"
          >
            <el-image
              :src="DialogFrom.certPhotoUrl || require('@/assets/develop.png')"
              fit="contain"
              class="imgCenter"
            ></el-image>
          </el-upload>
        </el-form-item>

        <el-form-item
          label="视频简介："
          prop="certVideo"
          class="form-item course"
        >
          <uploadAuth
            @childFn="vedioIds"
            :btnshow="true"
            :btnshow2="true"
            :disabled="btnshow"
            :modal="false"
            :showVideoId="{
              kpointVideoId: DialogFrom.certVideo,
            }"
            style="width: 320px;float:left;"
          />
           <el-button type="danger" plain @click="clearVideo" style="float:left;" v-if="DialogFrom.certVideo">清空</el-button>
        </el-form-item>
        <el-form-item label="证书样板：" prop="samplePlateUrl">
          <el-upload
            :on-change="handlebusinessLicense3"
            :before-upload="$beforeAvatarUpload"
            :http-request="$requestMine"
            :show-file-list="false"
            class="img-el-upload"
            action
            accept="image/png, image/gif, image/jpg, image/jpeg"
            style="width: 320px;float:left;"
          >
            <el-image
              :src="
                DialogFrom.samplePlateUrl || require('@/assets/develop.png')
              "
              fit="contain"
              class="imgCenter"
            ></el-image>
          </el-upload>
          <el-button type="danger" plain @click="clearImg" style="float: left;" v-if="DialogFrom.samplePlateUrl">清空</el-button>
        </el-form-item>
        <el-form-item label="证书展示页：" prop="certIntroId">
          <el-select v-model="DialogFrom.certIntroId" placeholder="请选择" clearable>
            <el-option
              v-for="item in options"
              :key="item.introId"
              :label="item.certName"
              :value="item.introId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="DialogCancel">取 消</el-button>
        <el-button type="primary" @click="DialogSure()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import uploadAuth from "@/components/uploadAuth";
let editor = {};
export default {
  components: {
    Empty,
    PageNum,
    uploadAuth,
  },
  mixins: [List],
  data: function () {
    return {
      // 证书名称
      certName: "",
      // 证书标签
      certLabel: "",
      // 上架状态
      certState: "",
      // 招生状态
      certRecruitState: "",
      // 上架状态字典
      certStateList: [],
      // 招生状态字典
      certRecruitStateList: [],
      // 新增/编辑证书 - 弹框
      DialogVisible: false,
      // 新增/编辑证书 - 弹框 - title
      DialogTitle: "新增",
      // 证书展示
      options: [],
      // 证书分类：
      courseTypeTree: [],
      // 证书等级
      certLevelData: [],
      // 新增证书 - 数据
      DialogFrom: {
        certName: "", //证书名称
        certType: "", //证书分类
        certLevel: "", //证书等级
        certCategoryName: "",
        certPrice: "", //市场价格
        recruitStudentsTime: "", //招生时间
        certIntroId: "", //证书展示页
        certCover: "", //简介封面
        certCoverUrl: "", //简介封面
        samplePlate: "", //证书样板
        samplePlateUrl: "", //证书样板
        certVideo: "", //简介
        certPhoto: "", //内容图片
        certPhotoUrl: "", //内容图片
      },
      // 新增证书 - 数据 - 校验
      DialogFromRules: {
        certName: [
          { required: true, message: "请输入证书名称", trigger: "blur" },
        ],
        certType: [
          { required: true, message: "请选择证书分类", trigger: "change" },
        ],
        certLevel: [
          { required: true, message: "请选择证书等级", trigger: "change" },
        ],
        certPrice: [
          { required: true, message: "请输入价格", trigger: "change" },
        ],
        recruitStudentsTime: [
          { required: true, message: "请选择招生时间", trigger: "change" },
        ],
        certCoverUrl: [
          { required: true, message: "请上传封面", trigger: "change" },
        ],
        // certVideo: [
        //   { required: true, message: "请上传视频", trigger: "change" },
        // ],
        certPhotoUrl: [
          { required: true, message: "请上传内容图片", trigger: "change" },
        ],
      },
    };
  },
  mounted() {},
  created() {
    this.getLevel();
    this.getDictionaries();
  },
  methods: {
    // 清空证书样板
    clearImg(){
        this.DialogFrom.samplePlate = "" //证书样板
        this.DialogFrom.samplePlateUrl = "" //证书样板
    },
    // 清空视频简介
    clearVideo(){
        this.DialogFrom.certVideo = "" //简介
    },
    // 获取 - 对应字典
    getDictionaries() {
      // 获取 - 上架状态字典
      const list_1 = this.$setDictionary("MINICERTSTATE", "list");
      // 获取 - 招生状态字典
      const list_2 = this.$setDictionary("MINI_CERT_RECRUIT_STATE", "list");
      for (const key in list_1) {
        this.certStateList.push({
          id: key,
          name: list_1[key],
        });
      }
      for (const key in list_2) {
        this.certRecruitStateList.push({
          id: key,
          name: list_2[key],
        });
      }
    },
    //获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        certName: this.certName,
        certLabel: this.certLabel,
        certState: this.certState,
        certRecruitState: this.certRecruitState,
      };
      this.doFetch(
        {
          url: "/mini/cert/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    //上传-简介封面图-事件
    handlebusinessLicense(res) {
      this.uploadFetch(res.raw);
    },
    //上传-简介封面图-方法
    uploadFetch(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.DialogFrom.certCoverUrl = ret.data.fileURL || "";
        that.DialogFrom.certCover = ret.data.fileKey || "";
      });
    },
    //上传-内容展示-事件
    handlebusinessLicense1(res) {
      this.uploadFetch1(res.raw);
    },
    //上传-内容展示-方法
    uploadFetch1(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.DialogFrom.certPhoto = ret.data.fileKey || "";
        that.DialogFrom.certPhotoUrl = ret.data.fileURL || "";
      });
    },
    //上传-证书样板-事件
    handlebusinessLicense3(res) {
      this.uploadFetch3(res.raw);
    },
    //上传-证书样板-方法
    uploadFetch3(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.DialogFrom.samplePlate = ret.data.fileKey || "";
        that.DialogFrom.samplePlateUrl = ret.data.fileURL || "";
      });
    },
    //上传视频简介
    vedioIds(msg) {
      this.videoId = msg;
      this.DialogFrom.certVideo = msg;
      if (this.ruleForm.certVideo) {
        this.$refs.ruleForm.validateField("Uploadcourseware");
      }
    },
    /* 新增||编辑 */
    EditDialog(stu, row) {

      this.DialogVisible = true;
      this.getCertificateList();
      this.getCategoryList();
      if (stu == "edit") {
        this.DialogTitle = "编辑证书";
        this.getInfo(row.certId);
      }
    },
    //获取证书详情
    getInfo(certId) {
      this.$post(
        "/mini/cert/getInfo",
        {
          certId,
        },
        3000,
        true,
        2
      )
        .then((ret) => {
          this.DialogFrom = {
            ...this.DialogFrom,
            ...ret.data,
            recruitStudentsTime: [
              ret.data.startTime.replaceAll("/", "-"),
              ret.data.endTime.replaceAll("/", "-"),
            ],
          };
        })
        .catch((err) => {
          return;
        });
    },
    //选择证书分类
    change(val) {
      let obj = {};
      obj = this.courseTypeTree.find((item) => {
        //遍历list的数据
        return item.certCategoryId === val; //筛选出匹配数据
      });
      this.DialogFrom.certCategoryName = obj.certCategoryName;
    },
    //新增确认
    DialogSure() {
      this.$refs.DialogFromRef.validate((valid) => {
        if (valid) {
          let data = {
            certName: this.DialogFrom.certName, // 证书名称
            certType: this.DialogFrom.certType, // 证书分类
            certLevel: this.DialogFrom.certLevel, // 证书等级
            certTypeName: this.DialogFrom.certCategoryName, // 证书分类
            certPrice: this.DialogFrom.certPrice, // 市场价格
            certIntroId: this.DialogFrom.certIntroId,
            certCover: this.DialogFrom.certCover, // 封面
            samplePlate: this.DialogFrom.samplePlate, // 封面
            // certVideo: this.DialogFrom.certVideo, // 视频简介
            certPhoto: this.DialogFrom.certPhoto, // 内容图片
          };
          if (this.DialogFrom.certVideo) {
              data.certVideo = this.DialogFrom.certVideo;// 视频简介
          }
          if (this.DialogFrom.recruitStudentsTime) {
            data.startTime = this.DialogFrom.recruitStudentsTime[0];
            data.endTime = this.DialogFrom.recruitStudentsTime[1];
          }
          if (this.DialogFrom.certId) {
            data.certId = this.DialogFrom.certId;
          }
          this.$post(
            this.DialogFrom.certId ? "/mini/cert/modify" : "/mini/cert/insert",
            data,
            3000,
            true,
            2
          )
            .then((ret) => {
              if (ret.status == 0) {
                this.$message.success("保存成功");
                this.empty();
                this.DialogVisible = false;
                this.getData(-1);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          return false;
        }
      });
    },
    //新增取消
    DialogCancel() {
      this.empty();
      this.DialogVisible = false;
    },
    //清空新增/编辑数据
    empty() {
      this.DialogFrom = {
        certName: "", //证书名称
        certType: "", //证书分类
        certLevel: "", //证书等级
        certCategoryName: "",
        certPrice: "", //市场价格
        recruitStudentsTime: "", //招生时间
        certIntroId: "", //证书展示页
        certCover: "", //简介封面
        certCoverUrl: "", //简介封面
        samplePlate: "", //证书样板
        samplePlateUrl: "", //证书样板
        certVideo: "", //简介
        certPhoto: "", //内容图片
        certPhotoUrl: "", //内容图片
      };

      this.$refs.DialogFromRef.resetFields();
    },
    //获取 - 证书展示
    getCertificateList() {
      this.$post(
        "/mini/certinfo/list",
        {
          certName: "",
        },
        3000,
        true,
        2
      )
        .then((ret) => {
          this.options = ret.data;
        })
        .catch((err) => {
          return;
        });
    },
    //获取分类
    getCategoryList() {
      this.$post("/minicert/minicategory/list", {}, 3000, true, 2)
        .then((res) => {
          if (res.status == 0) {
            this.courseTypeTree = res.data;
            return;
          }
        })
        .catch(() => {
          return;
        });
    },
    //上架/下架
    certificateRelease(certId) {
      this.$post("/mini/cert/state", { certId }, 3000, true, 2).then((ret) => {
        if (ret.status == "0") {
          this.$message.success("发布成功");
          this.getData(-1);
        }
      });
    },
    //终止招生
    certificateStopEnrollment(certId) {
      this.$post("/mini/cert/stop", { certId }, 3000, true, 2).then((ret) => {
        if (ret.status == "0") {
          this.$message.success("已终止招生成功");
          this.getData(-1);
        }
      });
    },
    //查看报名学员
    certificateViewEnrollment(certId) {
      this.$router.push({
        path: "/web/certificateStudentList",
        query: {
          certId: certId,
        },
      });
    },
    //获取证书等级
    getLevel() {
      // MINI_CERT_LEVEL
      const certLevel = this.$setDictionary("MINI_CERT_LEVEL", "list");
      for (const key in certLevel) {
        this.certLevelData.push({
          value: key,
          label: certLevel[key],
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-progress-bar{
  margin-left: 15px;
  width: 110px;
}
</style>